/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 09/01/2020
 * @Example
 */
import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W75F2010_actions";
import { delay } from "redux-saga";

export default function W75F2010Sagas() {
  return [
    watchGetDataGrid(),
    watchGetForm(),
    watchGetCboPeriod(),
    watchGetCboAppStatus(),
    watchGetCboAbsentType(),
    watchSaveAbsent(),
    watchDeleteData(),
    watchEditAbsent(),
  ];
}

export function* getForm(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/standard/load-form-approval", data.params);
    if (response && response.data) {
      yield put({ type: types.W75F2010_GET_FORM_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get form error");
  }
}

export function* watchGetForm() {
  while (true) {
    const watcher = yield takeLatest(types.W75F2010_GET_FORM, getForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get data grid
export function* getDataGrid(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w75f2010/load-form-data", data.params);
    if (response && response.data) {
      yield put({
        type: types.W75F2010_LOAD_GRID_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get form error");
  }
}

export function* watchGetDataGrid() {
  while (true) {
    const watcher = yield takeLatest(types.W75F2010_LOAD_GRID, getDataGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboPeriod(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w75f2010/load-cbo-period", data.params);
    if (response && response.data) {
      yield put({
        type: types.W75F2010_LOAD_CBO_PERIOD_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get combo periods error");
  }
}

export function* watchGetCboPeriod() {
  while (true) {
    const watcher = yield takeLatest(
      types.W75F2010_LOAD_CBO_PERIOD,
      getCboPeriod
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get Cbo Approval Staus
export function* getCboAppStatus(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w84f3000/load-approval-status", data.params );
    if (response && response.data) {
      yield put({
        type: types.W75F2010_LOAD_APPROVAL_STATUS_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get organizational error");
  }
}

export function* watchGetCboAppStatus() {
  while (true) {
    const watcher = yield takeLatest(
      types.W75F2010_LOAD_APPROVAL_STATUS,
      getCboAppStatus
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get Cbo Absent Type
export function* getCboAbsentType(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w75f2010/load-cbo-absent-type");
    if (response && response.data) {
      yield put({
        type: types.W75F2010_LOAD_CBO_ABSENT_TYPE_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get combo absent type error");
  }
}

export function* watchGetCboAbsentType() {
  while (true) {
    const watcher = yield takeLatest(
      types.W75F2010_LOAD_CBO_ABSENT_TYPE,
      getCboAbsentType
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* saveAbsent(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w75f2010/save", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("save absent error");
  }
}

export function* watchSaveAbsent() {
  while (true) {
    const watcher = yield takeLatest(types.W75F2010_SAVE_ABSENT, saveAbsent);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* editAbsent(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w75f2010/edit", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("save absent error");
  }
}

export function* watchEditAbsent() {
  while (true) {
    const watcher = yield takeLatest(types.W75F2010_EDIT_ABSENT, editAbsent);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
//deleteData
export function* deleteData(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w75f2000/delete", data.params);
    if (response && response.data) {
      yield put({
        type: types.W75F2010_DELETE_DATA_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("save absent error");
  }
}

export function* watchDeleteData() {
  while (true) {
    const watcher = yield takeLatest(types.W75F2010_DELETE_DATA, deleteData);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
