/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 5/5/2020
 * @Example
 */

export const types = {
    W51F1001_GET_CAPTION: "W51F1001_GET_CAPTION",
    W51F1001_GET_CAPTION_SUCCESS: "W51F1001_GET_CAPTION_SUCCESS",
    W51F1001_GET_MASTER: "W51F1001_GET_MASTER",
    W51F1001_GET_MASTER_SUCCESS: "W51F1001_GET_MASTER_SUCCESS",
    W51F1001_GET_DETAIL: "W51F1001_GET_DETAIL",
    W51F1001_GET_DETAIL_SUCCESS: "W51F1001_GET_DETAIL_SUCCESS",
};

export function getCaption(params, cb) {
    return {
        type: types.W51F1001_GET_CAPTION,
        cb,
        params,
    };
}

export function getMaster(params, cb) {
    return {
        type: types.W51F1001_GET_MASTER,
        cb,
        params,
    };
}

export function getDetail(params, cb) {
    return {
        type: types.W51F1001_GET_DETAIL,
        cb,
        params,
    };
}
