/**
 * @copyright 20202 @ DigiNet
 * @author THIEN PHAM
 * @create 8/20/2019
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W77F1001_actions";
import { delay } from "redux-saga";

export default function W77F1001Sagas() {
    return [watchLoadForm(), watchCboAbsentType()];
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w77f1001/load-form", data.params);
        if (response && response.data) {
            yield put({ type: types.W77F1001_LOAD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1001_LOAD_FORM, loadForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadCboAbsentType(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w77f1001/combo-absent-type");
        if (response && response.data) {
            yield put({ type: types.W77F1001_LOAD_CBO_ABSENT_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo error");
    }
}

export function* watchCboAbsentType() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1001_LOAD_CBO_ABSENT_TYPE, loadCboAbsentType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
