/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 31/10/2019
 * @Example
 */

import {types} from "./W75F4090_actions";

const initialState = {
    getCombo: null,
    getList: null
};

export default function(state = initialState,action = {}) {
    if(action.type === types.W75F4090_GET_COMBO_SUCCESS)
    {
        return {
            ...state,
            getCombo: action.data
        }
    }
    if(action.type === types.W75F4090_GET_LIST_SUCCESS)
    {
        return {
            ...state,
            getList: action.data
        }
    }
    return state
}
