import { types }             from "./W89F1000_actions";

const initialState = {
    getCboReportTypes: [],
    getCboReportIDs: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W98F1000_LOAD_CBO_REPORT_TYPE_SUCCESS: {
            return { ...state, getCboReportTypes: action.data };
        }
        case types.W98F1000_LOAD_CBO_REPORT_TYPE_ID_SUCCESS: {
            return { ...state, getCboReportIDs: action.data };
        }
        default: {
            return state;
        }
    }
}
