/**
 * @copyright 20202 @ DigiNet
 * @author THIEN PHAM
 * @create 8/20/2019
 * @Example
 */
export const types = {
    W77F1001_LOAD_FORM: "W77F1001_LOAD_FORM",
    W77F1001_LOAD_FORM_SUCCESS: "W77F1001_LOAD_FORM_SUCCESS",
    W77F1001_LOAD_CBO_ABSENT_TYPE: "W77F1001_LOAD_CBO_ABSENT_TYPE",
    W77F1001_LOAD_CBO_ABSENT_TYPE_SUCCESS: "W77F1001_LOAD_CBO_ABSENT_TYPE_SUCCESS",
};

export function loadForm(params, cb) {
    return {
        type: types.W77F1001_LOAD_FORM,
        params,
        cb
    }
}

export function loadCboAbsentType(cb) {
    return {
        type: types.W77F1001_LOAD_CBO_ABSENT_TYPE,
        cb
    }
}