/**
 * @copyright 20202 @ DigiNet
 * @author THIEN PHAM
 * @create 8/20/2019
 * @Example
 */

import Api from '../../../services/api';
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W77F1000_actions";
import {delay} from "redux-saga";

export default function W77F1000Sagas() {
    return [ watchLoadGrid() ];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1000/load-gird', data.params);
        if (response && response.data) {
            yield put({type: types.W77F1000_LOAD_GRID_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadGrid() {
    while (true){
        const watcher = yield takeLatest(types.W77F1000_LOAD_GRID, loadGrid);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}