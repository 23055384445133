export const types = {
    W98F1000_LOAD_CBO_REPORT_TYPE: 'W98F1000_LOAD_CBO_REPORT_TYPE',
    W98F1000_LOAD_CBO_REPORT_TYPE_SUCCESS: 'W98F1000_LOAD_CBO_REPORT_TYPE_SUCCESS',
    W98F1000_LOAD_CBO_REPORT_TYPE_ID: 'W98F1000_LOAD_CBO_REPORT_TYPE_ID',
    W98F1000_LOAD_CBO_REPORT_TYPE_ID_SUCCESS: 'W98F1000_LOAD_CBO_REPORT_TYPE_ID_SUCCESS',
    W98F1000_LOAD_ITEM_CBO_REPORT_STRUCTURE: 'W98F1000_LOAD_ITEM_CBO_REPORT_STRUCTURE',
    W98F1000_LOAD_ITEM_CBO_REPORT_TYPE_SUCCESS: 'W98F1000_LOAD_ITEM_CBO_REPORT_TYPE_SUCCESS',
    W98F1000_REPORT_EXPORT: 'W98F1000_REPORT_EXPORT',
    W98F1000_REPORT_PASSING_PARAMETER: 'W98F1000_REPORT_PASSING_PARAMETER',
    W98F1000_GET_CBO_CONTROl_DATA: 'W98F1000_GET_CBO_CONTROl_DATA',

};

export function getCboReportType(params,cb) {
    return{
        type: types.W98F1000_LOAD_CBO_REPORT_TYPE,
        params,
        cb
    }
}

export function getItemCboReportStructure(params,cb) {
    return{
        type: types.W98F1000_LOAD_ITEM_CBO_REPORT_STRUCTURE,
        params,
        cb
    }
}

export function getCboReportTypeID(params,cb) {
    return{
        type: types.W98F1000_LOAD_CBO_REPORT_TYPE_ID,
        params,
        cb
    }
}

export function exportFileTemplate(params,cb) {
    return{
        type: types.W98F1000_REPORT_EXPORT,
        params,
        cb
    }
}

export function passParametersExport(params,cb) {
    return{
        type: types.W98F1000_REPORT_PASSING_PARAMETER,
        params,
        cb
    }
}

export function getCboControlData(params,cb) {
    return{
        type: types.W98F1000_GET_CBO_CONTROl_DATA,
        params,
        cb
    }
}
