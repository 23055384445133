/**
 * @copyright 20202 @ DigiNet
 * @author THIEN PHAM
 * @create 8/20/2019
 * @Example
 */

import {types} from "./W77F1000_actions"

const initialState = {
    dataGrid: [],
};

export default function(state = initialState,action = {}) {
    switch (action.type) {
        case types.W77F1000_LOAD_GRID_SUCCESS: {
            return { ...state, dataGrid: action.data };
        }
        default: {
            return state;
        }
    }
}