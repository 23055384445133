export const types = {
    W77f1010_GET_DATA_GRID: 'W77f1010_GET_DATA_GRID',
    W77f1010_DELETE: 'W77f1010_DELETE',
};

export function getDataGrid(params,cb) {
    return{
        type: types.W77f1010_GET_DATA_GRID,
        params,
        cb
    }
}

export function deleteDataGrid(params,cb) {
    return{
        type: types.W77f1010_DELETE,
        params,
        cb
    }
}
