import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W77F1013_actions";
import { delay } from "redux-saga";

export default function W77F1013Sagas() {
    return [
        watchGetCboCostTypes(),
        watchGetFieldHide(),
        watchGetInfoBusinessTrips(),
        watchGetCostWorkPlansZero(),
        watchGetCostWorkPlansOne(),
        watchGetWorkingDiary(),
        watchGetConvertibleValueCurrentcies(),
        watchAdd(),
        watchGetMCboProject(),
        watchGetCostAllocation(),
    ]
}

export function* getCboCostTypes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w77f1011/load-cbo-cost-type');
        if (response && response.data) {
            yield put({type: types.W77F1013_LOAD_CBO_COST_TYPE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get Cbo CostTypes error", e)
    }
}

export function* watchGetCboCostTypes() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_LOAD_CBO_COST_TYPE, getCboCostTypes);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getFieldHide(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1011/get-field-hide', data.params);
        if (response && response.data) {
            yield put({type: types.W77F1013_GET_FIELD_HIDE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get FieldHide error", e)
    }
}

export function* watchGetFieldHide() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_FIELD_HIDE, getFieldHide);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getInfoBusinessTrips(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1011/get-info-business-trip', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get Info Business Trips error", e)
    }
}

export function* watchGetInfoBusinessTrips() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_INFO_BUSINESS_TRIP, getInfoBusinessTrips);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCostWorkPlansZero(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1011/get-cost-work-plan', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get Cost Work Plans error", e)
    }
}

export function* watchGetCostWorkPlansZero() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_COST_WORK_PLAN_ZERO, getCostWorkPlansZero);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCostWorkPlansOne(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1011/get-cost-work-plan', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get Cost Work Plans error", e)
    }
}

export function* watchGetCostWorkPlansOne() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_COST_WORK_PLAN_ONE, getCostWorkPlansOne);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}



export function* getWorkingDiary(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1011/get-work-diary', data.params);
        if (response && response.data) {
            yield put({type: types.W77F1013_GET_WORKING_DIARY_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get Working Diary error", e)
    }
}

export function* watchGetWorkingDiary() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_WORKING_DIARY, getWorkingDiary);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getConvertibleValueCurrentcies(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1011/get-convertible-value-currencies', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get Convertible Value Currentcies error", e)
    }
}

export function* watchGetConvertibleValueCurrentcies() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_CONVERTiBLE_VALUE_CURRENCIES, getConvertibleValueCurrentcies);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCostAllocation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1013/get-cost-allocation', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get cost allocation error", e)
    }
}

export function* watchGetCostAllocation() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_COST_ALLOCATION, getCostAllocation);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getMCboProject(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2020/m-load-cbo-project');
        if (response && response.data) {
            yield put({type: types.W77F1013_GET_M_CBO_PROJECT_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get M Cbo Project", e)
    }
}

export function* watchGetMCboProject() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_GET_M_CBO_PROJECT, getMCboProject);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w77f1013/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("add data error", e)
    }
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W77F1013_SAVE, add);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
