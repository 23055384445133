export const types = {
    W51F1002_GET_GRID_EQUIPMENTS: 'W51F1002_GET_GRID_EQUIPMENTS',

};


export function getGridEquipments(params,cb) {
    return{
        type: types.W51F1002_GET_GRID_EQUIPMENTS,
        params,
        cb
    }
}



