/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 31/10/2019
 * @Example
 */
export const types = {
    W75F4090_GET_COMBO: "W75F4090_GET_COMBO",
    W75F4090_GET_COMBO_SUCCESS: "W75F4090_GET_COMBO_SUCCESS",
    W75F4090_GET_LIST: "W75F4090_GET_LIST",
    W75F4090_GET_LIST_SUCCESS: "W75F4090_GET_LIST_SUCCESS"
};

export function getCombo(params, cb) {
    return {
        type: types.W75F4090_GET_COMBO,
        params,
        cb
    }
}

export function getList(params, cb) {
    return {
        type: types.W75F4090_GET_LIST,
        params,
        cb
    }
}
