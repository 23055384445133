import Api                                          from "../../../services/api";
import { put, cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types }                                    from "./W89F1000_actions";
import { delay }                                    from "redux-saga";
import Config                                       from "../../../config";

export default function W77F1013Sagas() {
    return [
        watchGetCboReportType(),
        watchGetCboReportID(),
        watchGetItemCboReportStructure(),
        watchExportFileTemplate(),
        watchPassParametersExport(),
        watchGetCboControlData(),

    ]
}

export function* getCboReportType(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/load-combo-report-type', data.params);
        if (response && response.data) {
            yield put({type: types.W98F1000_LOAD_CBO_REPORT_TYPE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get cbo report type error", e)
    }
}

export function* watchGetCboReportType() {
    while (true) {
        const watcher = yield takeLatest(types.W98F1000_LOAD_CBO_REPORT_TYPE, getCboReportType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboReportID(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/load-combo-report-id', data.params);
        yield put({type: types.W98F1000_LOAD_CBO_REPORT_TYPE_ID_SUCCESS, data: response.data});
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get cbo report type id error", e)
    }
}

export function* watchGetCboReportID() {
    while (true) {
        const watcher = yield takeLatest(types.W98F1000_LOAD_CBO_REPORT_TYPE_ID, getCboReportID);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getItemCboReportStructure(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/load-report-structure', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("get item cbo report structure error", e)
    }
}

export function* watchGetItemCboReportStructure() {
    while (true) {
        const watcher = yield takeLatest(types.W98F1000_LOAD_ITEM_CBO_REPORT_STRUCTURE, getItemCboReportStructure);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

/**
 * Export excel template...
 * @param data: {params, cb}
 * @param params: {
 *     token,
 *     ReportTypeID
 *     ReportID,
 *     TemplateURL,
 *     isPDF,
 *     Params,
 * }
 * @returns {IterableIterator<Promise<true>|*>}
 */

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data
}


export function* exportFileTemplate(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport('/report/export', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("export template error", e)
    }
}

export function* watchExportFileTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.W98F1000_REPORT_EXPORT, exportFileTemplate);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* passParametersExport(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/report/passing-parameter', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("report pass param error", e)
    }
}

export function* watchPassParametersExport() {
    while (true) {
        const watcher = yield takeLatest(types.W98F1000_REPORT_PASSING_PARAMETER, passParametersExport);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboControlData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/report/load-control-data', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log("load control data error", e)
    }
}

export function* watchGetCboControlData() {
    while (true) {
        const watcher = yield takeEvery(types.W98F1000_GET_CBO_CONTROl_DATA, getCboControlData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
