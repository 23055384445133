/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 5/5/2020
 * @Example
 */

import { types } from "./W51F1001_actions";

const initialState = {
    getCaption: [],
    getMaster: [],
    getDetail: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W51F1001_GET_CAPTION_SUCCESS: {
            return { ...state, getCaption: action.data };
        }
        case types.W51F1001_GET_MASTER_SUCCESS: {
            return { ...state, getMaster: action.data };
        }
        case types.W51F1001_GET_DETAIL_SUCCESS: {
            return { ...state, getDetail: action.data };
        }
        default: {
            return state;
        }
    }
}
