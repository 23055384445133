/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/23/2019
 * @Example 
 */

import { types } from "./W75F2000_actions";

const initialState = {
    getForm: [],
    getLeaveTypes: [],
    getRegisterTypes: [],
    getMinUnitRecordLeave: {},
    getMinUnitQuantity: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W75F2000_GET_LEAVE_TYPE_SUCCESS:
            return { ...state, getLeaveTypes: action.data };
        case types.W75F2000_GET_CBO_REGISTER_TYPE_SUCCESS:
            return { ...state, getRegisterTypes: action.data };
        case types.W75F2000_GET_FORM_SUCCESS:
            return { ...state, getForm: action.data };
        case types.W75F2000_GET_MIN_UNIT_RECORD_LEAVE_SUCCESS:
            return { ...state, getMinUnitRecordLeave: action.data };
        case types.W75F2000_GET_MIN_UNIT_QUANTITY_SUCCESS:
            return { ...state, getMinUnitQuantity: action.data };
        default:
            return state;
    }
}
