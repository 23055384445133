/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 6/1/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W51F1001_actions";
import { delay } from "redux-saga";

export default function W51F1001Sagas() {
  return [watchGetCaption(), watchGetMaster(), watchGetDetail()];
}

export function* getCaption(data) {
  try {
    yield delay(300);
    const res = yield Api.get("/w51f1001/load-caption", data.params);
    if (res && res.data) {
      yield put({
        type: types.W51F1001_GET_CAPTION_SUCCESS,
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetCaption() {
  while (true) {
    const watcher = yield takeLatest(types.W51F1001_GET_CAPTION, getCaption);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getMaster(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w51f1001/load-master", data.params);
    if (res && res.data) {
      yield put({
        type: types.W51F1001_GET_MASTER_SUCCESS,
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetMaster() {
  while (true) {
    const watcher = yield takeLatest(types.W51F1001_GET_MASTER, getMaster);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getDetail(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w51f1001/load-detail", data.params);
    if (res && res.data) {
      yield put({
        type: types.W51F1001_GET_DETAIL_SUCCESS,
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetDetail() {
  while (true) {
    const watcher = yield takeLatest(types.W51F1001_GET_DETAIL, getDetail);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
