/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
export const types = {
    W75F2000_GET_LEAVE_TYPE: "W75F2000_GET_LEAVE_TYPE",
    W75F2000_GET_LEAVE_TYPE_SUCCESS: "W75F2000_GET_LEAVE_TYPE_SUCCESS",
    W75F2000_GET_LEAVE: "W75F2000_GET_LEAVE",
    W75F2000_GET_FORM: "W75F2000_GET_FORM",
    W75F2000_GET_FORM_SUCCESS: "W75F2000_GET_FORM_SUCCESS",
    W75F2000_CHECK_REGISTER_TYPE: "W75F2000_CHECK_REGISTER_TYPE",
    W75F2000_GET_CBO_REGISTER_TYPE: "W75F2000_GET_CBO_REGISTER_TYPE",
    W75F2000_GET_CBO_REGISTER_TYPE_SUCCESS: "W75F2000_GET_CBO_REGISTER_TYPE_SUCCESS",
    W75F2000_GET_GRID: "W75F2000_GET_GRID",
    W75F2000_GET_GRID_HISTORY: "W75F2000_GET_GRID_HISTORY",
    W75F2000_GET_GRID_DETAIL_ADD: "W75F2000_GET_GRID_DETAIL_ADD",
    W75F2000_GET_GRID_DETAIL_EDIT: "W75F2000_GET_GRID_DETAIL_EDIT",
    W75F2000_DELETE_LEAVE: "W75F2000_DELETE_LEAVE",
    W75F2000_SAVE_LEAVE: "W75F2000_SAVE_LEAVE",
    W75F2000_EDIT_LEAVE: "W75F2000_EDIT_LEAVE",
    W75F2000_GET_MIN_UNIT_RECORD_LEAVE: "W75F2000_GET_MIN_UNIT_RECORD_LEAVE",
    W75F2000_GET_MIN_UNIT_RECORD_LEAVE_SUCCESS: "W75F2000_GET_MIN_UNIT_RECORD_LEAVE_SUCCESS",
    W75F2000_GET_MIN_UNIT_QUANTITY: "W75F2000_GET_MIN_UNIT_QUANTITY",
    W75F2000_GET_MIN_UNIT_QUANTITY_SUCCESS: "W75F2000_GET_MIN_UNIT_QUANTITY_SUCCESS",
};

export function getForm(params, cb) {
    return {
        type: types.W75F2000_GET_FORM,
        params,
        cb
    }
}

export function getLeaveTypes(params, cb) {
    return {
        type: types.W75F2000_GET_LEAVE_TYPE,
        params,
        cb
    }
}

export function getLeave(params, cb) {
    return {
        type: types.W75F2000_GET_LEAVE,
        params,
        cb
    }
}

export function checkRegisterType(params, cb) {
    return {
        type: types.W75F2000_CHECK_REGISTER_TYPE,
        params,
        cb
    }
}

export function saveLeave(params, cb) {
    return {
        type: types.W75F2000_SAVE_LEAVE,
        params,
        cb
    }
}

export function editLeave(params, cb) {
    return {
        type: types.W75F2000_EDIT_LEAVE,
        params,
        cb
    }
}

export function getGrid(params, cb) {
    return {
        type: types.W75F2000_GET_GRID,
        params,
        cb
    }
}

export function getGridHistory(params, cb) {
    return {
        type: types.W75F2000_GET_GRID_HISTORY,
        params,
        cb
    }
}

export function getGridDetailAdd(params, cb) {
    return {
        type: types.W75F2000_GET_GRID_DETAIL_ADD,
        params,
        cb
    }
}

export function getGridDetailEdit(params, cb) {
    return {
        type: types.W75F2000_GET_GRID_DETAIL_EDIT,
        params,
        cb
    }
}


export function getCboRegisterType(cb) {
    return {
        type: types.W75F2000_GET_CBO_REGISTER_TYPE,
        cb
    }
}

export function deleteLeave(params, cb) {
    return {
        type: types.W75F2000_DELETE_LEAVE,
        params,
        cb
    }
}

export function getMinUnitRecordLeave(cb) {
    return {
        type: types.W75F2000_GET_MIN_UNIT_RECORD_LEAVE,
        cb
    }
}

export function getMinUnitQuantity(cb) {
    return {
        type: types.W75F2000_GET_MIN_UNIT_QUANTITY,
        cb
    }
}