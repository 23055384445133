/**
 * @copyright 20202 @ DigiNet
 * @author THIEN PHAM
 * @create 8/20/2019
 * @Example
 */

import {types} from "./W77F1001_actions"

const initialState = {
    getForm: [],
    getCboAbsentTypeData: [],
};

export default function(state = initialState,action = {}) {
    switch (action.type) {
        case types.W77F1001_LOAD_FORM_SUCCESS: {
            return { ...state, getForm: action.data };
        }
        case types.W77F1001_LOAD_CBO_ABSENT_TYPE_SUCCESS: {
            return { ...state, getCboAbsentTypeData: action.data };
        }
        default: {
            return state;
        }
    }
}