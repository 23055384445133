export const types = {
    W77F1013_LOAD_CBO_COST_TYPE: "W77F1013_LOAD_CBO_COST_TYPE",
    W77F1013_LOAD_CBO_COST_TYPE_SUCCESS: "W77F1013_LOAD_CBO_COST_TYPE_SUCCESS",
    W77F1013_GET_FIELD_HIDE: "W77F1013_GET_FIELD_HIDE",
    W77F1013_GET_FIELD_HIDE_SUCCESS: "W77F1013_GET_FIELD_HIDE_SUCCESS",
    W77F1013_GET_INFO_BUSINESS_TRIP: "W77F1013_GET_INFO_BUSINESS_TRIP",
    W77F1013_GET_COST_WORK_PLAN_ZERO: "W77F1013_GET_COST_WORK_PLAN_ZERO",
    W77F1013_GET_COST_WORK_PLAN_ONE: "W77F1013_GET_COST_WORK_PLAN_ONE",
    W77F1013_GET_WORKING_DIARY: "W77F1013_GET_WORKING_DIARY",
    W77F1013_GET_WORKING_DIARY_SUCCESS: "W77F1013_GET_WORKING_DIARY_SUCCESS",
    W77F1013_GET_COST_ALLOCATION: "W77F1013_GET_COST_ALLOCATION",
    W77F1013_GET_COST_ALLOCATION_SUCCESS: "W77F1013_GET_COST_ALLOCATION_SUCCESS",
    W77F1013_GET_CONVERTiBLE_VALUE_CURRENCIES: "W77F1013_GET_CONVERTiBLE_VALUE_CURRENCIES",
    W77F1013_GET_CONVERTiBLE_VALUE_CURRENCIES_SUCCESS: "W77F1013_GET_CONVERTiBLE_VALUE_CURRENCIES_SUCCESS",
    W77F1013_SAVE: "W77F1013_SAVE",
    W77F1013_GET_M_CBO_PROJECT: "W77F1013_GET_M_CBO_PROJECT",
    W77F1013_GET_M_CBO_PROJECT_SUCCESS: "W77F1013_GET_M_CBO_PROJECT_SUCCESS",
};

export function getCboCostTypes(cb) {
    return {
        type: types.W77F1013_LOAD_CBO_COST_TYPE,
        cb
    };
}

export function getFieldHide(params, cb) {
    return {
        type: types.W77F1013_GET_FIELD_HIDE,
        params,
        cb
    };
}

export function getInfoBusinessTrips(params, cb) {
    return {
        type: types.W77F1013_GET_INFO_BUSINESS_TRIP,
        params,
        cb
    };
}

export function getCostWorkPlansZero(params, cb) {
    return {
        type: types.W77F1013_GET_COST_WORK_PLAN_ZERO,
        params,
        cb
    };
}

export function getCostWorkPlansOne(params, cb) {
    return {
        type: types.W77F1013_GET_COST_WORK_PLAN_ONE,
        params,
        cb
    };
}


export function getWorkingDiary(params, cb) {
    return {
        type: types.W77F1013_GET_WORKING_DIARY,
        params,
        cb
    };
}

export function getConvertibleValueCurrentcies(params, cb) {
    return {
        type: types.W77F1013_GET_CONVERTiBLE_VALUE_CURRENCIES,
        params,
        cb
    };
}

export function getCostAllocation(params, cb) {
    return {
        type: types.W77F1013_GET_COST_ALLOCATION,
        params,
        cb
    };
}

export function getMCboProject(cb) {
    return {
        type: types.W77F1013_GET_M_CBO_PROJECT,
        cb
    };
}


export function add(params, cb) {
    return {
        type: types.W77F1013_SAVE,
        params,
        cb
    };
}
