export const types = {
    W77F1011_LOAD_CBO_COST_TYPE: "W77F1011_LOAD_CBO_COST_TYPE",
    W77F1011_LOAD_CBO_COST_TYPE_SUCCESS: "W77F1011_LOAD_CBO_COST_TYPE_SUCCESS",
    W77F1011_GET_FIELD_HIDE: "W77F1011_GET_FIELD_HIDE",
    W77F1011_GET_FIELD_HIDE_SUCCESS: "W77F1011_GET_FIELD_HIDE_SUCCESS",
    W77F1011_GET_INFO_BUSINESS_TRIP: "W77F1011_GET_INFO_BUSINESS_TRIP",
    W77F1011_GET_COST_WORK_PLAN: "W77F1011_GET_COST_WORK_PLAN",
    W77F1011_GET_COST_WORK_PLAN_SUCCESS: "W77F1011_GET_COST_WORK_PLAN_SUCCESS",
    W77F1011_GET_WORKING_DIARY: "W77F1011_GET_WORKING_DIARY",
    W77F1011_GET_WORKING_DIARY_SUCCESS: "W77F1011_GET_WORKING_DIARY_SUCCESS",
    W77F1011_GET_CONVERTiBLE_VALUE_CURRENCIES: "W77F1011_GET_CONVERTiBLE_VALUE_CURRENCIES",
    W77F1011_GET_CONVERTiBLE_VALUE_CURRENCIES_SUCCESS: "W77F1011_GET_CONVERTiBLE_VALUE_CURRENCIES_SUCCESS",
    W77F1011_SAVE: "W77F1011_SAVE",
    W77F1011_EDIT: "W77F1011_EDIT",
};

export function getCboCostTypes(cb) {
    return {
        type: types.W77F1011_LOAD_CBO_COST_TYPE,
        cb
    };
}

export function getFieldHide(params, cb) {
    return {
        type: types.W77F1011_GET_FIELD_HIDE,
        params,
        cb
    };
}

export function getInfoBusinessTrips(params, cb) {
    return {
        type: types.W77F1011_GET_INFO_BUSINESS_TRIP,
        params,
        cb
    };
}

export function getCostWorkPlans(params, cb) {
    return {
        type: types.W77F1011_GET_COST_WORK_PLAN,
        params,
        cb
    };
}


export function getWorkingDiary(params, cb) {
    return {
        type: types.W77F1011_GET_WORKING_DIARY,
        params,
        cb
    };
}

export function getConvertibleValueCurrentcies(params, cb) {
    return {
        type: types.W77F1011_GET_CONVERTiBLE_VALUE_CURRENCIES,
        params,
        cb
    };
}

export function add(params, cb) {
    return {
        type: types.W77F1011_SAVE,
        params,
        cb
    };
}

export function edit(params, cb) {
    return {
        type: types.W77F1011_EDIT,
        params,
        cb
    };
}